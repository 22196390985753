body {
  font-family: Arial;
  font-size: 16px;
}

.comic-list {
  li {
    margin-bottom: 10px;
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: white;
  }

  a:visited {
    color: peachpuff;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.col-left {
  width: 20%;
  text-align: left;
}

.col-right {
  width: 20%;
  text-align: right;
}

.col-middle {
  width: 60%;
  text-align: center;
}

.comic-header {
  color: white; 
}




.top-bar {
  padding-left: 10px;
  padding-right: 10px;
  background-color: black; 
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  border-top: 1px solid white;
}

.top-bar-title {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.top-bar-link {
  color: white;
  text-decoration: none;
}

.banner {
  max-width: 782px; 
  margin-left: auto; 
  margin-right: auto; 
  display: block;
}

.outer-comic-house {
  max-width: 800px; 
  margin: 10px auto; 
  background-color: #888888; 
  border-style: solid; 
  border-color: black; 
  border-width: 2px; 
  padding: 15px;
}

.inner-comic-house {
  margin-top: 15px;
}

.comic-image {
  max-width: 100%; 
  margin-left: auto; 
  margin-right: auto; 
  display: block;
}

.comic-navigation-link {
  color: white;
  text-decoration: none;
}

.comic-header-component {
  font-size: 30px;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.go-to-first-or-last {
  .comic-navigation-link {
    color: #CCCCCC;
  }
}

@media screen and (max-width: 550px) {
  .col-left {
    order: 1;
    width: 50%;
  }

  .col-right {
    order: 2;
    width: 50%;
  }

  .col-middle {
    width: 100%;
    order: 3;
  }

  .outer-comic-house {
    padding: 5px;
  }

  .inner-comic-house {
    margin-top: 5px;
  }

  .comic-header-component {
    font-size: 25px;
  }
}
